<template>
    <div class="seongdong_curation_wrap age" :class="getCurationCss">
        <ul class="setting_target_detail">
            <h2 class="curation_body_list_title"> 연령대 </h2>
            <ul class="curation_list">
                <li class="curation_list_item" v-for="(item, index) in getAgeList" :key="item.id"
                    :class="{ 'mr0': index === getAgeList.length - 1 }">
                    <label :for="item.id" class="curation_item" :class="age.includes(item.value) ? 'active' : ''"
                           @click="clickcuration(item.value)">{{ item.text
                           }}</label>
                    <input :id="item.id" type="radio" hidden :value="item.value" v-model="age">
                </li>
            </ul>
        </ul>
    </div>
</template>
<script>
export default {
  name: 'SeongDongCurationAge',
  emits: ["send-curation"],
  props: {
    ageGroup: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      age: ''
    };
  },
  created () {
    this.age = this.ageGroup;
  },

  mounted () {

  },

  methods: {
    async clickcuration (val) {
      await this.$nextTick();
      this.age = val;
      this.$emit('send-curation', {
        type: 'ageGroup',
        value: this.age
      });
    }
  },
  computed: {
    getCurationCss () {
      return this.$store.getters.gettersCurationCssClass;
    },
    getAgeList () {
      return this.$store.getters['curation/getAge'];
    }
  }
};
</script>
<style scoped src="@/assets/css/seongdonglibary/seongdongcuration.css"></style>
